<template>
  <div class="settings--content">
    <loading-state v-if="uiFlags.isFetching || uiFlags.isFetchingAgentBot" />
    <form v-else @submit.prevent="updateActiveAgentBot">
      <settings-section
        :title="$t('AGENT_BOTS.BOT_CONFIGURATION.TITLE')"
        :sub-title="$t('AGENT_BOTS.BOT_CONFIGURATION.DESC')"
      >
        <div class="medium-9 columns settings-item">
          <label>
            <select v-model="selectedAgentBotId">
              <option value="" disabled selected>
                {{ $t('AGENT_BOTS.BOT_CONFIGURATION.SELECT_PLACEHOLDER') }}
              </option>
              <option
                v-for="agentBot in agentBots"
                :key="agentBot.id"
                :value="agentBot.id"
              >
                {{ agentBot.name }}
              </option>
            </select>
          </label>
          <div class="button-container">
            <woot-submit-button
              :button-text="$t('AGENT_BOTS.BOT_CONFIGURATION.SUBMIT')"
              :loading="uiFlags.isSettingAgentBot"
            />
            <woot-button
              type="button"
              :disabled="!selectedAgentBotId"
              :loading="uiFlags.isDisconnecting"
              variant="smooth"
              color-scheme="alert"
              class="button--disconnect"
              @click="disconnectBot"
            >
              {{ $t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECT') }}
            </woot-button>
          </div>
        </div>
      </settings-section>

      <settings-section
        v-if="activeAgentBotOptions"
        title="Tirar o cliente do fluxo em caso de espera"
        sub-title="O que o converx deve fazer caso o cliente fique muito tempo parado no chatbot, sem dar resposta."
      >
        <div class="medium-9 columns settings-item">
          <select v-model="activeAgentBotOptions.action_type">
            <option :value="null" selected>Nao realizar nenhuma acao</option>
            <option value="reopen">Abrir conversa</option>
            <option value="resolve">Resolver conversa</option>
          </select>
          <select
            v-if="activeAgentBotOptions.action_type != null"
            v-model="activeAgentBotOptions.execution_interval"
          >
            <option :value="null" selected>Selecione tempo limite</option>
            <option value="2">2 minutos</option>
            <option value="5">5 minutos</option>
            <option value="10">10 minutos</option>
            <option value="15">15 minutos</option>
            <option value="20">20 minutos</option>
            <option value="30">30 minutos</option>
            <option value="60">1 hora</option>
            <option value="120">2 horas</option>
            <option value="240">4 horas</option>
            <option value="360">6 horas</option>
            <option value="480">8 horas</option>
            <option value="720">12 horas</option>
            <option value="1440">24 horas</option>
          </select>
          <select
            v-if="
              activeAgentBotOptions.action_type === 'reopen' &&
              accountTeams.length
            "
            v-model="activeAgentBotOptions.team_assign"
          >
            <option :value="null" selected>Selecione um time</option>
            <option
              v-for="team in accountTeams"
              :key="team.id"
              :value="team.id"
            >
              {{ team.name }}
            </option>
          </select>
          <label
            v-if="activeAgentBotOptions.action_type === 'resolve'"
            class="editor-wrap"
          >
            Mensagem que será encaminhada para o cliente
            <woot-message-editor
              v-model.trim="activeAgentBotOptions.resolve_message"
              class="message-editor"
              :is-format-mode="true"
              :class="{ editor_warning: error }"
              placeholder="Por motivos de falta de contato, estou encerrando esse atendimento"
              @input="activeAgentBotOptions.resolve_message = $event"
            />
            <span v-if="error" class="editor-warning__message">
              A mensagem é obrigatória
            </span>
          </label>
          <div class="button-container">
            <woot-submit-button
              button-text="Atualizar"
              :loading="uiFlags.isSettingAgentBot"
            />
          </div>
        </div>
      </settings-section>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingsSection from 'dashboard/components/SettingsSection';
import LoadingState from 'dashboard/components/widgets/LoadingState';
import alertMixin from 'shared/mixins/alertMixin';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';

export default {
  components: {
    LoadingState,
    SettingsSection,
    WootMessageEditor,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedAgentBotId: null,
      error: false,
    };
  },
  computed: {
    ...mapGetters({
      agentBotOptions: 'agentBots/getActiveAgentBotOptions',
      agentBots: 'agentBots/getBots',
      uiFlags: 'agentBots/getUIFlags',
      teamsList: 'teams/getTeams',
    }),
    activeAgentBot() {
      return this.$store.getters['agentBots/getActiveAgentBot'](this.inbox.id);
    },
    accountTeams() {
      return this.$store.getters['teams/getTeams'];
    },
    activeAgentBotOptions() {
      return this.$store.getters['agentBots/getActiveAgentBotOptions'](
        this.inbox.id
      );
    },
  },
  watch: {
    activeAgentBot() {
      this.selectedAgentBotId = this.activeAgentBot.id;
    },
  },
  mounted() {
    this.$store.dispatch('agentBots/get');
    this.$store.dispatch('agentBots/fetchAgentBotInbox', this.inbox.id);
    this.$store.dispatch('agentBots/fetchAgentBotInboxOption', this.inbox.id);
  },

  methods: {
    async updateActiveAgentBot() {
      try {
        await this.$store.dispatch('agentBots/setAgentBotInbox', {
          inboxId: this.inbox.id,
          // Added this to make sure that empty values are not sent to the API
          botId: this.selectedAgentBotId ? this.selectedAgentBotId : undefined,
          options: {
            action_type: this.activeAgentBotOptions.action_type,
            execution_interval: this.activeAgentBotOptions.execution_interval,
            team_assign: this.activeAgentBotOptions.team_assign,
            resolve_message: this.activeAgentBotOptions.resolve_message ?? '',
          },
        });
        this.showAlert(this.$t('AGENT_BOTS.BOT_CONFIGURATION.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_BOTS.BOT_CONFIGURATION.ERROR_MESSAGE'));
      }
    },
    async disconnectBot() {
      try {
        await this.$store.dispatch('agentBots/disconnectBot', {
          inboxId: this.inbox.id,
        });
        this.showAlert(
          this.$t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECTED_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          error?.message ||
            this.$t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECTED_ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.button--disconnect {
  margin-left: var(--space-small);
}
</style>
