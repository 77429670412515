const type = [
  {
    name: 'Contato',
    id: 'Contact'
  },
  {
    name: 'Agente',
    id: 'User'
  },
];

export default type;
