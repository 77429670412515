<template>
  <div class="primary--sidebar">
    <nav class="menu vertical">
      <primary-nav-item
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :icon-fill="menuItem.iconFill || 'currentColor'"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
        :is-beta="menuItem.isBeta || false"
        :is-new="menuItem.isNew || false"
      />
    </nav>
  </div>
</template>
<script>
import PrimaryNavItem from './PrimaryNavItem';

export default {
  components: {
    PrimaryNavItem,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.primary--sidebar {
  display: flex;
  flex-direction: column;
  width: var(--space-jumbo);
  border-right: 1px solid var(--s-50);
  box-sizing: content-box;
  height: 100%;
  flex-shrink: 0;
}

.menu {
  align-items: center;
}
</style>
