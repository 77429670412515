<template>
  <section>
    <woot-button
      v-if="isAdmin"
      v-tooltip.right="tooltip"
      class-names="notifications-link--button"
      variant="clear"
      color-scheme="secondary"
      :class="{ 'is-active': false }"
      @click="applyConfig"
    >
      <fluent-icon :icon="icon" />
      <span label="Beta" class="beta">Beta</span>
    </woot-button>

    <woot-modal
      :show.sync="showPopup"
      :show-button-close="false"
      :on-close="hidePopup"
    >
      <div class="column content-box">
        <woot-modal-header
          class="text-center"
          :header-title="$t('OBSERVER_MODE.TITLE')"
        />

        <form @submit.prevent="submit" class="row">
          <div class="medium-12 columns">
            <p>
              {{ $t('OBSERVER_MODE.DESCRIPTION') }}
            </p>

            <ul>
              <li>{{ $t('OBSERVER_MODE.LI_1') }}</li>
              <li>{{ $t('OBSERVER_MODE.LI_2') }}</li>
              <li>{{ $t('OBSERVER_MODE.LI_3') }}</li>
            </ul>
          </div>

          <div class="modal-footer">
            <woot-button @click.prevent="hidePopup">
              {{ $t('OBSERVER_MODE.BUTTON_CANCEL') }}
            </woot-button>

            <woot-button>
              {{ $t('OBSERVER_MODE.BUTTON_ACTIVE') }}
            </woot-button>
          </div>
        </form>
      </div>
    </woot-modal>
  </section>
</template>

<script>
import adminMixin from '../../../mixins/isAdmin';

import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [adminMixin],
  data() {
    return {
      showPopup: false,
      icon: 'eye-show',
      tooltip: '',
    };
  },
  methods: {
    ...mapActions('observerMode', ['setMode']),
    applyConfig() {
      if (!this.isObserverMode) {
        return this.openPopup();
      }

      this.setMode(false);
    },
    submit() {
      this.setMode(true);
      this.hidePopup();
    },
    setItems() {
      if (!this.isAdmin) {
        this.setMode(false);
      }

      setTimeout(() => {
        this.icon = this.isObserverMode ? 'eye-hide' : 'eye-show';
        this.tooltip = this.isObserverMode
          ? this.$t('OBSERVER_MODE.TOOLTIP_DISABLE')
          : this.$t('OBSERVER_MODE.TOOLTIP_ENABLE');
      }, 400);
    },
    openPopup() {
      this.showPopup = true;
    },
    hidePopup() {
      this.showPopup = false;
    },
  },
  computed: {
    ...mapGetters({
      isObserverMode: 'observerMode/isObserverMode',
    }),
  },
  mounted() {
    this.setItems();
  },
  watch: {
    isObserverMode() {
      this.setItems();
    },
  },
};
</script>

<style scoped lang="scss">
section {
  margin: auto 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.notifications-link--button {
  display: flex;
  position: relative;
  border-radius: var(--border-radius-large);
  border: 1px solid transparent;
  color: var(--s-600);
  margin-right: var(--space-smaller);

  &:hover {
    background: var(--w-50);
    color: var(--s-600);
  }

  &:focus {
    border-color: var(--w-500);
  }

  .beta {
    padding-right: var(--space-smaller) !important;
    padding-left: var(--space-smaller) !important;
    margin-left: var(--space-smaller) !important;
    display: inline-block;
    font-size: 8px;
    font-weight: var(--font-weight-medium);
    line-height: 13px;
    border: 1px solid transparent;
    border-radius: 2em;
    background: var(--g-800);
    border-color: var(--g-700);
    position: absolute;
    left: 12px;
    bottom: 3px;
    color: #fff;
  }
}

p {
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-one);
}

ul {
  li {
    font-size: var(--font-size-small);
  }
}

.modal-footer {
  margin-top: var(--space-snug);

  button {
    margin-left: 0 !important;
  }

  width: 100%;
  justify-content: space-between;
}
</style>