<template>
  <div
    class="conversations-list-wrap"
    :class="{
      hide: !showConversationList,
      'list--full-width': isOnExpandedLayout,
    }"
  >
    <slot />
    <div class="chat-list__top">
      <h1 class="page-title text-truncate" :title="pageTitle">
        {{ pageTitle }}
      </h1>
    </div>

    <div class="boards-conversations">
      <priority-list
        v-for="board in statuses"
        :key="board.status"
        :conversation-inbox="conversationInbox"
        :team-id="teamId"
        :label="label"
        :conversation-type="conversationType"
        :folders-id="foldersId"
        :showConversation-list="showConversationList"
        :is-on-expanded-layout="isOnExpandedLayout"
        :status="board.status"
        :title="board.title"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PriorityList from './PriorityList';

export default {
  components: {
    PriorityList,
  },
  props: {
    conversationInbox: {
      type: [String, Number],
      default: 0,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    conversationType: {
      type: String,
      default: '',
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
    showConversationList: {
      default: true,
      type: Boolean,
    },
    isOnExpandedLayout: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
    }),
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.activeInbox);
    },
    pageTitle() {
      if (this.inbox.name) {
        return this.inbox.name;
      }
      if (this.activeTeam.name) {
        return this.activeTeam.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      return this.$t('PRIORITY_CONTACTS_PAGE.HEADER');
    },
    activeTeam() {
      if (this.teamId) {
        return this.$store.getters['teams/getTeam'](this.teamId);
      }
      return {};
    },
    statuses() {
      return [{ title: 'Abertas', status: 'open' }];
    },
  },
};
</script>

<style scoped lang="scss">
.page-title {
  margin-top: var(--space-two);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-big);
}
</style>