<template>
    <div class="column content-box">
        <woot-modal-header :header-title="$t('INTEGRATION_SETTINGS.WEBHOOK.HEADERS.TITLE')">
          <p v-html="$t('INTEGRATION_SETTINGS.WEBHOOK.HEADERS.DESCRIPTION')"></p>
        </woot-modal-header>
        <form @submit.prevent="handleSubmit">
            <div class="row" v-for="header in headers" :key="header.id">
                <div class="medium-5">
                    <woot-input
                        label="Chave"
                        placeholder="Chave"
                        v-model="header.key"
                        required
                    />
                </div>
                <div class="medium-5 ml-1">
                    <woot-input
                        label="Valor"
                        placeholder="Valor"
                        v-model="header.value"
                        required
                    />
                </div>
                <div class="align-center-middle ml-1">
                    <woot-button
                        v-tooltip.top="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.BUTTON_TEXT')"
                        variant="smooth"
                        color-scheme="alert"
                        size="tiny"
                        icon="dismiss-circle"
                        @click.prevent="deleteHeader(header.id)"
                    />
                </div>
            </div>
            <div class="column">
                <woot-button
                    class="button clear" 
                    icon="add"
                    @click.prevent="addHeader"
                >
                    Adicionar novo cabeçalho
                </woot-button>
            </div>
            <div class="medium-12 columns">
                <div class="modal-footer justify-content-end w-full">
                    <woot-submit-button
                        :loading="submiting"
                        :button-text="$t('INTEGRATION_SETTINGS.WEBHOOK.HEADERS.BUTTON_SUBMIT')"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
    mixins: [alertMixin],
    props: {
        value: {
            type: Object,
            required: true,
        },
        id: {
            type: Number,
            default: null,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            submiting: false,
            headers: [],
        }
    },
    computed: {
        headersNormalized() {
            const maps = {};
            this.headers.filter(h => !!h.key && !!h.value).forEach(header => {
                maps[header.key] = header.value;
            });
            return maps || {};
        },
        hasHeadersEmpty() {
            return this.headers.some(header => !header.key || !header.value);
        },
    },
    created() {
        if (this.value.headers) {
            this.headers = Object.keys(this.value.headers).map((key, id) => ({
                key,
                value: this.value.headers[key],
                id,
            }));
        }
    },
    mounted() {
        if (this.headers.length === 0) {
            this.addHeader();
        }
    },
    methods: {
        addHeader() {
            this.headers.push({
                id: this.headers.length + 1,
            });
        },
        deleteHeader(id) {
            this.headers = this.headers.filter(header => header.id !== id);
        },
        async handleSubmit() {
            if (this.hasHeadersEmpty) {
                this.showAlert('Todos os campos são obrigatórios');
                return;
            }

            if (this.submiting) {
                return;
            }

            this.submiting = true;

            try {
                await this.$store.dispatch('webhooks/update', {
                    webhook:{
                        headers: this.headersNormalized
                    },
                    id: this.id,
                });
                this.showAlert(
                    this.$t('INTEGRATION_SETTINGS.WEBHOOK.EDIT.API.SUCCESS_MESSAGE')
                );
                this.onClose();
            } catch (error) {
                const alertMessage = error.response.data.message || this.$t('INTEGRATION_SETTINGS.WEBHOOK.EDIT.API.ERROR_MESSAGE');
                this.showAlert(alertMessage);
            } finally {
                this.submiting = false;
            }
        },
    },
}
</script>

<style lang="scss">
.modal-container {
    width: 70rem !important;
}
.ml-1 {
    margin-left: 1rem;
}
</style>