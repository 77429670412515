<template>
  <div class="metric-item">
    <h3 :class="['heading', headingClass]">
      {{ title }}
    </h3>
    <p class="metric">
      {{ value }}
      <span v-if="percentage !== undefined" class="percentage">
        ({{ percentage }}%)
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'MetricItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    percentage: {
      type: [String, Number],
      default: undefined,
    },
    headingClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.metric-item .heading {
  margin-bottom: var(--space-slab);
  font-size: var(--font-size-default);
  color: var(--b-900);
}
.priority-grid .metric-item .heading {
  color: var(--b-50);
}
.metric-item .metric {
  font-size: var(--font-size-big);
  color: var(--b-900);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-smaller);
}
.metric-item .percentage {
  font-size: var(--font-size-small);
  color: var(--b-600);
}
.heading {
  padding: var(--space-micro) var(--space-snug);
  border-radius: var(--border-radius-full);
  text-align: center;
  color: var(--white);
}
.heading.urgent {
  background-color: var(--urgent-color);
}
.heading.high {
  background-color: var(--high-color);
}
.heading.medium {
  background-color: var(--medium-color);
}
.heading.low {
  background-color: var(--low-color);
}
.heading.none {
  background-color: var(--none-color);
}
</style>