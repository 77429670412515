<template>
  <transition name="menu-slide">
    <div
      v-if="show"
      v-on-clickaway="onClickAway"
      class="options-menu dropdown-pane"
      :class="{ 'dropdown-pane--open': show }"
    >
      <availability-status />
      <li class="divider" />
      <woot-dropdown-menu>
        <woot-dropdown-item class="show-logs--toggle">
          <div class="info-wrap">
            <fluent-icon
              v-tooltip.right-start="$t('SIDEBAR_ITEMS.SHOW_LOG_MESSAGES')"
              icon="info"
              size="14"
              class="info-icon"
            />

            <span class="show-logs--text">
              {{ $t('SIDEBAR_ITEMS.SHOW_LOG_MESSAGES') }}
            </span>
          </div>

          <woot-switch
            size="small"
            class="show-logs--switch"
            :value="currentUserShowLogConversation"
            @input="updateShowLogs"
          />
        </woot-dropdown-item>
        <woot-dropdown-divider />
        <woot-dropdown-item v-if="showChangeAccountOption">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            icon="arrow-swap"
            @click="$emit('toggle-accounts')"
          >
            {{ $t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            icon="keyboard"
            @click="handleKeyboardHelpClick"
          >
            {{ $t('SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <router-link
            v-slot="{ href, isActive, navigate }"
            :to="`/app/accounts/${accountId}/profile/settings`"
            custom
          >
            <a
              :href="href"
              class="button small clear secondary"
              :class="{ 'is-active': isActive }"
              @click="e => handleProfileSettingClick(e, navigate)"
            >
              <fluent-icon icon="person" size="14" class="icon icon--font" />
              <span class="button__content">
                {{ $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}
              </span>
            </a>
          </router-link>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="currentUser.type === 'SuperAdmin'">
          <a
            href="/super_admin"
            class="button small clear secondary"
            target="_blank"
            rel="noopener nofollow noreferrer"
            @click="$emit('close')"
          >
            <fluent-icon
              icon="content-settings"
              size="14"
              class="icon icon--font"
            />
            <span class="button__content">
              {{ $t('SIDEBAR_ITEMS.SUPER_ADMIN_CONSOLE') }}
            </span>
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <a
            href="https://sugestoes.phonetrack.app"
            target="_blank"
            class="button small clear secondary"
            @click="$emit('close')"
          >
            <fluent-icon icon="person" size="14" class="icon icon--font" />
            <span class="button__content">
              {{ $t('SIDEBAR_ITEMS.FEEDBACK') }}
            </span>
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            icon="power"
            @click="logout"
          >
            {{ $t('SIDEBAR_ITEMS.LOGOUT') }}
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import Auth from '../../../api/auth';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider';
import AvailabilityStatus from 'dashboard/components/layout/AvailabilityStatus';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { LocalStorage } from 'shared/helpers/localStorage';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatus,
    WootDropdownDivider,
  },
  mixins: [clickaway],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentUserShowLogConversation: !!LocalStorage.get(
        LOCAL_STORAGE_KEYS.SHOW_LOG_CONVERSATION
      ),
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
    showChangeAccountOption() {
      if (this.globalConfig.createNewAccountFromDashboard) {
        return true;
      }

      const { accounts = [] } = this.currentUser;
      return accounts.length > 1;
    },
    feedbackPath() {
      return frontendURL(`accounts/${this.accountId}/feedback`);
    },
  },
  watch: {
    currentUserShowLogConversation(value) {
      LocalStorage.set(LOCAL_STORAGE_KEYS.SHOW_LOG_CONVERSATION, value);
      bus.$emit(BUS_EVENTS.SHOW_LOG_CONVERSATION);
    },
  },
  methods: {
    frontendURL,
    handleProfileSettingClick(e, navigate) {
      this.$emit('close');
      navigate(e);
    },
    handleKeyboardHelpClick() {
      this.$emit('key-shortcut-modal');
      this.$emit('close');
    },
    logout() {
      let promAuthLogout = Auth.logout();
      if (window.inteliaIDURL) {
        promAuthLogout.then(resp => {
          location.href = `${window.inteliaIDURL}/security/logout`;
        });
      }
    },
    onClickAway() {
      if (this.show) this.$emit('close');
    },
    updateShowLogs(showLogConversation) {
      this.currentUserShowLogConversation = showLogConversation;
    },
  },
};
</script>

<style lang="scss" scoped>
.options-menu.dropdown-pane {
  right: var(--space-smaller);
  top: var(--space-jumbo);
  min-width: var(--space-giga);
  z-index: var(--z-index-high);
}

.show-logs--toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--space-smaller);
  margin: 0;

  .info-wrap {
    display: flex;
    align-items: center;
  }

  .info-icon {
    margin-top: -1px;
  }

  .show-logs--switch {
    margin: -1px var(--space-micro) 0;
  }

  .show-logs--text {
    margin: 0 var(--space-smaller);
    font-size: var(--font-size-mini);
    font-weight: var(--font-weight-medium);
    color: var(--s-700);
  }
}
</style>
