const priorities = [
  {
    name: 'Baixa',
    id: '0'
  },
  {
    name: 'Média',
    id: '1'
  },
  {
    name: 'Alta',
    id: '2'
  },
  {
    name: 'Urgente',
    id: '3'
  },
];

export default priorities;
