<template>
  <div v-if="show" class="content-alert flex-center" :class="type">
    <div class="text-center">
      <fluent-icon icon="warning" :size="iconSize" />
    </div>

    <div>
      <h3 v-if="showTitle">{{ getTitle }}</h3>
      <p v-html="content"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    title: {
      type: String,
    },
    type: {
      type: String,
      default: 'success',
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: String,
      default: '40',
    },
  },
  computed: {
    getTitle() {
      if (this.title) return this.title;

      return this.$t('CHAT_LIST.ALERT.WARNING');
    },
  },
};
</script>

<style lang="scss" scoped>
.content-alert {
  padding: var(--space-small);

  border-radius: var(--border-radius-small);

  &.success {
    background: var(--g-50);

    svg {
      color: var(--g-800);
    }
  }

  &.danger {
    background: var(--r-50);

    svg {
      color: var(--r-800);
    }
  }

  &.warning {
    background: #ffcd18;

    svg {
      color: #1f2d3d;
    }
  }

  div:first-of-type {
    flex: 10 1;
    margin-right: var(--space-small);
  }

  div:last-child {
    flex: 90 1;

    h3 {
      font-size: var(--font-size-default);
      font-weight: var(--font-weight-black);
    }

    p {
      font-size: var(--font-size-mini);
      line-height: 1.5;
      margin-bottom: 0;
    }
  }
}
</style>