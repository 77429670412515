import { FEATURE_FLAGS } from '../../../../featureFlags';
import { frontendURL } from '../../../../helper/URLHelper';

const primaryMenuItems = accountId => [
  {
    icon: 'chat',
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
    roles: ['administrator', 'agent', 'seller', 'supervisor', 'analyzer', 'assistant', 'embed'],
  },
  {
    icon: 'priority-icon',
    key: 'priority_contacts',
    label: 'PRIORITY_CONTACTS',
    toState: frontendURL(`accounts/${accountId}/priority-contacts`),
    toStateName: 'priority_contacts',
    roles: ['administrator', 'agent', 'supervisor', 'seller'],
    isNew: true,
  },
  {
    icon: 'boards',
    iconFill: 'none',
    key: 'boards',
    label: 'BOARDS',
    toState: frontendURL(`accounts/${accountId}/boards`),
    toStateName: 'boards_dashboard',
    roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
  },
  {
    icon: 'book-contacts',
    key: 'contacts',
    label: 'CONTACTS',
    featureFlag: FEATURE_FLAGS.CRM,
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
    roles: ['administrator', 'agent', 'supervisor', 'seller'],
  },
  {
    icon: 'arrow-trending-lines',
    key: 'reports',
    label: 'REPORTS',
    featureFlag: FEATURE_FLAGS.REPORTS,
    toState: frontendURL(`accounts/${accountId}/reports`),
    toStateName: 'settings_account_reports',
    roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
  },
  {
    icon: 'megaphone',
    key: 'campaigns',
    label: 'CAMPAIGNS',
    featureFlag: FEATURE_FLAGS.CAMPAIGNS,
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'settings_account_campaigns',
    roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
  },
  {
    icon: 'library',
    key: 'helpcenter',
    label: 'HELP_CENTER.TITLE',
    featureFlag: FEATURE_FLAGS.HELP_CENTER,
    toState: frontendURL(`accounts/${accountId}/portals`),
    toStateName: 'default_portal_articles',
    roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
  },
  {
    icon: 'settings',
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings`),
    toStateName: 'settings_home',
    roles: ['administrator', 'supervisor', 'agent', 'seller', 'assistant', 'analyzer'],
  },
  {
    icon: 'automation',
    key: 'menu_dashboard_apps',
    label: 'DASHBOARD_APPS',
    toState: frontendURL(`accounts/${accountId}/dashboard-apps`),
    toStateName: 'menu_dashboard_apps',
    roles: ['administrator', 'supervisor'],
  },
];

export default primaryMenuItems;
