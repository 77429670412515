<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    <a
      v-tooltip.right="$t(`SIDEBAR.${name}`)"
      :href="href"
      class="button clear button--only-icon menu-item"
      :class="{ 'is-active': isActive || isChildMenuActive }"
      :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
      :target="openInNewPage ? '_blank' : undefined"
      @click="navigate"
    >
      <fluent-icon :icon="icon" :fill="iconFill" />
      <span class="show-for-sr">{{ name }}</span>
      <span v-if="count" class="badge warning">{{ count }}</span>
      <span v-if="isBeta" label="Beta" class="beta">Beta</span>
      <span v-if="isNew" class="beta">{{ $t('INTEGRATION_SETTINGS.OPEN_AI.NEW') }}</span>
    </a>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconFill: {
      type: String,
      default: 'currentColor',
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: false,
    },
    isBeta: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  margin: var(--space-small) 0;
}

.menu-item {
  display: inline-flex;
  position: relative;
  border-radius: var(--border-radius-large);
  border: 1px solid transparent;
  color: var(--b-600);

  &:hover {
    background: var(--w-25);
    color: var(--b-600);
  }

  &:focus {
    border-color: var(--c-500);
  }

  &.is-active {
    background: var(--c-100);
    color: var(--c-500);
  }

  .beta {
    padding: 0 var(--space-smaller);
    margin-left: var(--space-smaller);
    display: inline-block;
    font-size: 8px;
    font-weight: var(--font-weight-medium);
    line-height: 13px;
    border: 1px solid var(--g-700);
    border-radius: 2em;
    background: var(--g-800);
    color: #fff;
    position: absolute;
    left: 12px;
    bottom: 3px;
  }
}

.icon {
  font-size: var(--font-size-default);
}

.badge {
  position: absolute;
  right: var(--space-minus-smaller);
  top: var(--space-minus-smaller);
}

</style>
