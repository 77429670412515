<template>
  <section v-if="showTopbar" class="woot-topbar small-12 columns">
    <logo
      :source="globalConfig.logoThumbnail"
      :name="globalConfig.installationName"
      :account-id="accountId"
    />

    <search />

    <div class="menu user-menu">
      <observer-mode />

      <support
        v-if="globalConfig.chatwootInboxToken"
        @show-support-chat-window="toggleSupportChatWindow"
      />

      <primary-nav-item
        v-if="isACustomBrandedInstance"
        icon="book-open-globe"
        name="DOCS"
        style="margin: auto 0"
        :to="docsPath"
      />

      <notification-bell @open-notification-panel="openNotificationPanel" />

      <alert
        class="alert-user-availability hide-on-mobile"
        :show-title="false"
        :show="showAlertUserAvailability"
        :content="$t(`SIDEBAR.ALERT_USER_AVAILABILITY.MESSAGE`, {status: availabilityDisplayLabel})"
        icon-size="24"
        type="danger"
      />

      <agent-details @toggle-menu="toggleOptions" />

      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import AgentDetails from './topbarComponents/AgentDetails';
import Logo from './topbarComponents/Logo';
import NotificationBell from './topbarComponents/NotificationBell';
import ObserverMode from './topbarComponents/ObserverMode';
import OptionsMenu from './topbarComponents/OptionsMenu';
import PrimaryNavItem from './sidebarComponents/PrimaryNavItem';
import Search from './topbarComponents/Search';
import Support from './topbarComponents/Support';
import Alert from 'shared/components/Alert';

import { frontendURL } from 'dashboard/helper/URLHelper';
import wootConstants from 'dashboard/constants/globals';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: {
    AgentDetails,
    Logo,
    NotificationBell,
    ObserverMode,
    OptionsMenu,
    PrimaryNavItem,
    Search,
    Support,
    Alert,
  },
  data() {
    return {
      showOptionsMenu: false,
    };
  },
  methods: {
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleSupportChatWindow() {
      window.zE('messenger', 'open');
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
      isACustomBrandedInstance: 'globalConfig/isACustomBrandedInstance',
      isEmbedding: 'isEmbedding',
      currentRole: 'getCurrentRole',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),
    docsPath() {
      return frontendURL(`accounts/${this.accountId}/docs`);
    },
    showTopbar() {
      if (this.currentRole == 'embed' || this.isEmbedding) {
        return false;
      }

      return true;
    },
    showAlertUserAvailability() {
      return this.currentUserAvailability !== 'online';
    },
    availabilityDisplayLabel() {
      const availabilityIndex = AVAILABILITY_STATUS_KEYS.findIndex(
        key => key === this.currentUserAvailability
      );
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[
        availabilityIndex
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.woot-topbar {
  background: var(--white);
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: var(--space-micro) solid var(--c-500);

  @media screen and (min-height: 1040px) {
    display: none;
  }
}

.user-menu {
  display: flex;
  margin: 0 var(--space-normal);

  @media screen and (max-width: 768px) {
    a {
      display: none;
    }
  }
}

.alert-user-availability {
  margin: var(--space-small);
  white-space: nowrap;
}

.hide-on-mobile {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
</style>