<template>
  <div v-if="isAIIntegrationEnabled" class="position-relative">
    <div v-if="!message">
      <woot-button
        v-tooltip.top-end="$t('INTEGRATION_SETTINGS.OPEN_AI.TITLE')"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleDropdown"
      >
        <span class="icon-text">
          <span class="icon">✨</span>
          <span>{{ $t('INTEGRATION_SETTINGS.OPEN_AI.TEXT') }}</span>
        </span>
      </woot-button>
      <span class="beta">{{ $t('INTEGRATION_SETTINGS.OPEN_AI.NEW') }}</span>
      <div
        v-if="showDropdown"
        v-on-clickaway="closeDropdown"
        class="dropdown-pane dropdown-pane--open ai-modal"
      >
        <h4 class="sub-block-title margin-top-1">
          {{ $t('INTEGRATION_SETTINGS.OPEN_AI.TEXT') }}
        </h4>
        <p class="ai-description">
          {{
            $t(
              'INTEGRATION_SETTINGS.OPEN_AI.DESCRIPTION'
            )
          }}
        </p>
        <div class="button-group">
          <woot-button
            color-scheme="primary"
            variant="smooth"
            size="small"
            :is-loading="uiFlags.reply_suggestion"
            @click="processEvent('reply_suggestion')"
          >
            {{ $t('INTEGRATION_SETTINGS.OPEN_AI.REPLY_TITLE') }}
          </woot-button>
          <woot-button
            color-scheme="primary"
            variant="smooth"
            size="small"
            :is-loading="uiFlags.summarize"
            @click="processEvent('summarize')"
          >
            {{ $t('INTEGRATION_SETTINGS.OPEN_AI.SUMMARY_TITLE') }}
          </woot-button>
        </div>
      </div>
    </div>

    <div v-else>
      <woot-button
        v-tooltip.top-end="$t('INTEGRATION_SETTINGS.OPEN_AI.TEXT')"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :disabled="true"
        class="disabled-button"
      >
        <span class="icon-text">
          <span class="icon">✨</span>
          <span>{{ $t('INTEGRATION_SETTINGS.OPEN_AI.TEXT') }}</span>
        </span>
      </woot-button>
      <span class="beta">{{ $t('INTEGRATION_SETTINGS.OPEN_AI.NEW') }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import OpenAPI from 'dashboard/api/integrations/openapi';
import alertMixin from 'shared/mixins/alertMixin';
import { OPEN_AI_EVENTS } from 'dashboard/helper/AnalyticsHelper/events';

export default {
  mixins: [alertMixin, clickaway],
  props: {
    conversationId: {
      type: Number,
      default: 0,
    },
    message: {
      type: String,
      default: '',
    },
    isPrivateNote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uiFlags: {
        rephrase: false,
        reply_suggestion: false,
        summarize: false,
      },
      showDropdown: false,
      activeTone: 'professional',
      tones: [
        {
          key: 'professional',
          value: this.$t(
            'INTEGRATION_SETTINGS.OPEN_AI.TONE.OPTIONS.PROFESSIONAL'
          ),
        },
        {
          key: 'friendly',
          value: this.$t('INTEGRATION_SETTINGS.OPEN_AI.TONE.OPTIONS.FRIENDLY'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      appIntegrations: 'integrations/getAppIntegrations',
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
    }),
    isAIIntegrationEnabled() {
      return this.appIntegrations.find(
        integration => integration.id === 'openai' && !!integration.hooks.length
      );
    },
    hookId() {
      return this.appIntegrations.find(
        integration => integration.id === 'openai' && !!integration.hooks.length
      ).hooks[0].id;
    },
    buttonText() {
      return this.uiFlags.isRephrasing
        ? this.$t('INTEGRATION_SETTINGS.OPEN_AI.BUTTONS.GENERATING')
        : this.$t('INTEGRATION_SETTINGS.OPEN_AI.BUTTONS.GENERATE');
    },
  },
  mounted() {
    if (!this.appIntegrations.length) {
      this.$store.dispatch('integrations/get');
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    async recordAnalytics({ type, tone }) {
      const event = OPEN_AI_EVENTS[type.toUpperCase()];
      if (event) {
        this.$track(event, {
          type,
          tone,
        });
      }
    },
    async processEvent(type = 'rephrase') {
      this.uiFlags[type] = true;
      const { locale } = this.getAccount(this.currentAccountId);
      try {
        const result = await OpenAPI.processEvent({
          hookId: this.hookId,
          type,
          content: this.message,
          tone: this.activeTone,
          conversationId: this.conversationId,
          locale,
        });
        const {
          data: { message: generatedMessage },
        } = result;
        this.$emit('replace-text', generatedMessage || this.message);
        this.closeDropdown();
        this.recordAnalytics({ type, tone: this.activeTone });
      } catch (error) {
        this.showAlert(this.$t('INTEGRATION_SETTINGS.OPEN_AI.GENERATE_ERROR'));
      } finally {
        this.uiFlags[type] = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ai-modal {
  width: 400px;
  right: 0;
  left: 0;
  padding: var(--space-normal);
  bottom: 34px;
  position: absolute;
  span {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
  }
  p.ai-description {
    color: var(--s-600);
    margin-top: 8px;
  }
  .button-group {
    display: flex;
    flex-direction: column;
    gap: var(--space-small);
  }
  .modal-footer {
    gap: var(--space-smaller);
  }
}
.beta {
  padding: 0 var(--space-smaller);
  margin-left: var(--space-small);
  display: inline-block;
  font-size: 8px;
  font-weight: var(--font-weight-medium);
  line-height: 13px;
  border: 1px solid var(--g-700);
  border-radius: 2em;
  background: var(--g-800);
  color: #fff;
  position: absolute;
  top: 5%;
  transform: translateY(-50%);
  right: 0;
}
.icon {
  font-size: 16px;
  margin-right: var(--space-smaller);
}
button {
  margin-right: var(--space-small);
}
</style>