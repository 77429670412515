<template>
  <div class="column content-box">
    <div class="row">
      <div
        class="column small-12 medium-8 conversation-metric metric-card-background"
      >
        <div class="metric-container">
          <div class="sections-container">
            <div class="header-section">
              <h2 class="column-title">
                {{ $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.OPEN_HEADER') }}
              </h2>
              <div class="metric-grid">
                <metric-item
                  v-for="(item, index) in metricItems.openMetrics"
                  :key="index"
                  :title="item.title"
                  :value="item.value"
                  :percentage="item.percentage"
                />
              </div>
            </div>
            <div class="priority-section">
              <h2 class="column-title">
                {{
                  $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.PRIORITY_HEADER')
                }}
              </h2>
              <div class="priority-grid">
                <metric-item
                  v-for="(item, index) in metricItems.priorityMetrics"
                  :key="index"
                  :title="item.title"
                  :value="item.value"
                  :percentage="item.percentage"
                  :heading-class="item.headingClass"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column small-12 medium-4">
        <metric-card :header="this.$t('OVERVIEW_REPORTS.AGENT_STATUS.HEADER')">
          <div
            v-for="(metric, name, index) in agentStatusMetrics"
            :key="index"
            class="metric-content column"
          >
            <h3 class="heading">
              <span
                v-if="metric.status"
                class="status-dot"
                :class="metric.status"
              ></span>
              {{ name }}
            </h3>
            <p class="metric">{{ metric.value }}</p>
          </div>
        </metric-card>
      </div>
    </div>
    <div class="row">
      <metric-card
        :header="this.$t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.HEADER')"
      >
        <template #control>
          <woot-button
            icon="arrow-download"
            size="small"
            variant="smooth"
            color-scheme="secondary"
            @click="downloadHeatmapData"
          >
            Download Report
          </woot-button>
        </template>
        <report-heatmap
          :heat-data="accountConversationHeatmap"
          :is-loading="uiFlags.isFetchingAccountConversationsHeatmap"
        />
      </metric-card>
    </div>
    <div class="row">
      <metric-card
        :header="this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.HEADER')"
      >
        <agent-table
          :agents="agents"
          :agent-metrics="agentConversationMetric"
          :page-index="pageIndex"
          :is-loading="uiFlags.isFetchingAgentConversationMetric"
          @page-change="onPageNumberChange"
        />
      </metric-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AgentTable from './components/overview/AgentTable';
import MetricCard from './components/overview/MetricCard';
import MetricItem from './components/overview/MetricItem';
import { OVERVIEW_METRICS } from './constants';
import ReportHeatmap from './components/Heatmap';

import endOfDay from 'date-fns/endOfDay';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import wootConstants from 'dashboard/constants/globals';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  name: 'LiveReports',
  components: {
    AgentTable,
    MetricCard,
    ReportHeatmap,
    MetricItem,
  },
  data() {
    return {
      pageIndex: 1,
    };
  },
  computed: {
    ...mapGetters({
      agentStatus: 'agents/getAgentStatus',
      agents: 'agents/getAgents',
      accountConversationMetric: 'getAccountConversationMetric',
      agentConversationMetric: 'getAgentConversationMetric',
      accountConversationHeatmap: 'getAccountConversationHeatmapData',
      conversationMetrics: 'getAccountConversationMetric',
      uiFlags: 'getOverviewUIFlags',
    }),
    metricItems() {
      return {
        openMetrics: [
          {
            title: this.$t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.OPEN_TOTAL'),
            value: this.conversationMetrics.open,
          },
          {
            title: this.$t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.UNASSIGNED'),
            value: this.conversationMetrics.unattended,
            percentage: this.conversationMetrics.unattended_percentage,
          },
        ],
        priorityMetrics: [
          {
            title: this.$t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.NONE'),
            value: this.conversationMetrics.none,
            percentage: this.conversationMetrics.none_percentage,
            headingClass: 'none',
          },
          {
            title: this.$t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.LOW'),
            value: this.conversationMetrics.low,
            percentage: this.conversationMetrics.low_percentage,
            headingClass: 'low',
          },
          {
            title: this.$t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.MEDIUM'),
            value: this.conversationMetrics.medium,
            percentage: this.conversationMetrics.medium_percentage,
            headingClass: 'medium',
          },
          {
            title: this.$t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.HIGH'),
            value: this.conversationMetrics.high,
            percentage: this.conversationMetrics.high_percentage,
            headingClass: 'high',
          },
          {
            title: this.$t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.URGENT'),
            value: this.conversationMetrics.urgent,
            percentage: this.conversationMetrics.urgent_percentage,
            headingClass: 'urgent',
          },
        ],
      };
    },
    agentStatusMetrics() {
      let metric = {};

      if (this.agentStatus.total !== undefined) {
        const totalMetricName = this.$t(`OVERVIEW_REPORTS.AGENT_STATUS.TOTAL`);
        metric[totalMetricName] = {
          value: this.agentStatus.total,
          status: null,
        };
      }

      Object.keys(this.agentStatus).forEach(key => {
        if (key !== 'total') {
          const metricName = this.$t(
            `OVERVIEW_REPORTS.AGENT_STATUS.${
              OVERVIEW_METRICS[key] || key.toUpperCase()
            }`
          );

          let status = null;
          if (AVAILABILITY_STATUS_KEYS.includes(key)) {
            status = key;
          }

          metric[metricName] = { value: this.agentStatus[key], status };
        }
      });

      return metric;
    },
    conversationMetrics() {
      const metrics = this.accountConversationMetric || {};
      return {
        open: metrics.open || 0,
        unattended: metrics.unattended || 0,
        urgent: metrics.urgent || 0,
        high: metrics.high || 0,
        medium: metrics.medium || 0,
        low: metrics.low || 0,
        none: metrics.none || 0,
        urgent_percentage: metrics.urgent_percentage || 0,
        high_percentage: metrics.high_percentage || 0,
        medium_percentage: metrics.medium_percentage || 0,
        low_percentage: metrics.low_percentage || 0,
        none_percentage: metrics.none_percentage || 0,
        unattended_percentage: metrics.unattended_percentage || 0,
      };
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.fetchAllData();

    this.fetchInterval = setInterval(() => {
      this.fetchAllData();
    }, 180000);

    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.fetchInterval);
    });
  },
  methods: {
    fetchAllData() {
      this.fetchAccountConversationMetric();
      this.fetchAgentConversationMetric();
      this.fetchHeatmapData();
    },
    downloadHeatmapData() {
      let to = endOfDay(new Date());

      this.$store.dispatch('downloadAccountConversationHeatmap', {
        to: getUnixTime(to),
      });
    },
    fetchHeatmapData() {
      if (this.uiFlags.isFetchingAccountConversationsHeatmap) {
        return;
      }

      let to = endOfDay(new Date());
      let from = startOfDay(subDays(to, 6));

      if (this.accountConversationHeatmap.length) {
        to = endOfDay(new Date());
        from = startOfDay(to);
      }

      this.$store.dispatch('fetchAccountConversationHeatmap', {
        metric: 'conversations_count',
        from: getUnixTime(from),
        to: getUnixTime(to),
        groupBy: 'hour',
        businessHours: false,
      });
    },
    fetchAccountConversationMetric() {
      this.$store.dispatch('fetchAccountConversationMetric', {
        type: 'account',
      });
    },
    fetchAgentConversationMetric() {
      this.$store.dispatch('fetchAgentConversationMetric', {
        type: 'agent',
        page: this.pageIndex,
      });
    },
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.fetchAgentConversationMetric();
    },
  },
};
</script>

<style scoped>
.metric-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-jumbo);
}
.sections-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--space-normal);
  flex-wrap: wrap;
}
.header-section,
.priority-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin-left: var(--space-large);
}
.column-title {
  margin-bottom: var(--space-large);
  font-size: var(--font-size-two);
  font-weight: var(--font-weight-bold);
  text-align: left;
}
.metric-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-normal);
  justify-items: start;
}
.priority-grid {
  display: flex;
  justify-content: space-between;
  gap: var(--space-normal);
  flex-wrap: wrap;
}
.metric-card-background {
  background-color: var(--v-50);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.metric-content .status-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
.metric-content .status-dot.online {
  background-color: var(--low-color) !important;
}
.metric-content .status-dot.busy {
  background-color: var(--medium-color) !important;
}
.metric-content .status-dot.offline {
  background-color: var(--none-color) !important;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  gap: var(--space-normal) !important;
}
.row > .column.small-12.medium-8,
.row > .column.small-12.medium-4 {
  display: flex;
  flex-direction: column;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}
.metric-item {
  min-height: 100%;
}
.row > .column {
  margin-bottom: var(--space-normal) !important;
}
.column.small-12.medium-4 {
  padding-right: var(--space-normal) !important;
}
</style>