import PriorityContactsView from './components/PriorityContactsView';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
    {
      path: frontendURL('accounts/:accountId/priority-contacts'),
      name: 'priority_contacts',
      roles: ['administrator', 'agent', 'supervisor', 'seller'],
      component: PriorityContactsView,
      props: () => {
        return { inboxId: 0 };
      },
    },
];
